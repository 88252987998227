<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="ar" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                أحدث تطبيقات GB MOD 2024
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">GB WhatsApp</h2>
                    <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      تحميل
                    </a>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">{{ item.title }}</h2>
                    <router-link class="mod-item-btn" :to="{name:`en-${item.name}`}">
                      تحميل
                    </router-link>
                  </div>
                </div>
              </div>
              <h2>ما هي ملفات واتساب مود APK؟</h2>
              <p>
              بينما يتطور تطبيق واتساب الرسمي مع التحديثات، فإن سرعته وميزاته المحدودة غالبًا ما تخيب آمال المستخدمين. لسد هذه الفجوة، قام مطورون مهرة بتعديل التطبيق الأصلي، مضيفين ميزات مثيرة وعملية تتجاوز ما يقدمه واتساب. نتيجة لذلك، تم تطوير تعديلات واتساب الشهيرة مثل GB واتساب، Fouad واتساب، وYo واتساب.
              </p>
              <p>
              في هذه الصفحة، ستجد معلومات تفصيلية حول هذه تعديلات واتساب APK. نوفر روابط تحميل آمنة، أدلة خطوة بخطوة، ومقدمات للميزات لمساعدتك على استكشاف هذه التطبيقات المعدلة والاستمتاع بها.
              </p>
              <p>
              لا تتردد في تنزيل تعديل واتساب APK الذي تفضله الآن!
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">سياسة الخصوصية</a>
          </div>
          <div class="copyright-bar">
            2025 © جميع الحقوق محفوظة <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      تحميل GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'ar',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      let params = window.location.search;
      window.location.href = '/ar/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>